<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Postavshiklar malumotlari</h3>
          </div>
        </div>

        <div class="card-body">
          <div>
            <v-tabs v-model="tab">
              <v-tab
                v-for="(item, index) in items"
                :key="item.tab"
                @click="tabNum(index)"
              >
                {{ item.tab }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <!-- 1 -->
              <v-tab-item class="mt-5">
                <div>
                  <div v-if="getCurrProviderDetails.juridical_type == 'JI'">
                    <h5 class="mb-5">Jismoniy shaxs</h5>
                    <div class="input__wrapper">
                      <div class="col-4">
                        <v-text-field
                          label="Mijoz nomi"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.full_name"
                          disabled
                        ></v-text-field>
                      </div>

                      <div class="col-4">
                        <v-text-field
                          label="Pochta manzili"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.pochta_address"
                          disabled
                        ></v-text-field>
                      </div>

                      <div class="col-4">
                        <v-text-field
                          label="Pasport raqami"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.passport_number"
                          disabled
                        ></v-text-field>
                      </div>

                      <div class="col-4">
                        <v-text-field
                          label="Oblast"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.adress_oblast.name"
                          disabled
                        ></v-text-field>
                      </div>

                      <div class="col-4">
                        <v-text-field
                          label="Region"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.adress_region.name"
                          disabled
                        ></v-text-field>
                      </div>

                      <div class="col-4">
                        <v-text-field
                          label="Status"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.status.status"
                          disabled
                        ></v-text-field>
                      </div>
                      <div class="col-4">
                        <v-text-field
                          label="Izoh"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.comments"
                          disabled
                        ></v-text-field>
                      </div>
                    </div>
                  </div>

                  <div v-if="getCurrProviderDetails.juridical_type == 'YU'">
                    <h5 class="mb-5">Yuridik shaxs</h5>

                    <div class="input__wrapper">
                      <div class="col-4">
                        <v-text-field
                          label="Mijoz nomi"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.full_name"
                          disabled
                        ></v-text-field>
                      </div>
                      <div class="col-4">
                        <v-text-field
                          label="INN"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.inn"
                          disabled
                        ></v-text-field>
                      </div>
                      <div class="col-4">
                        <v-text-field
                          label="OKED"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.oked"
                          disabled
                        ></v-text-field>
                      </div>
                      <div class="col-4">
                        <v-text-field
                          label="NDS raqami"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.code_nds"
                          disabled
                        ></v-text-field>
                      </div>

                      <div class="col-4">
                        <v-text-field
                          label="Oblast"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.adress_oblast.name"
                          disabled
                        ></v-text-field>
                      </div>

                      <div class="col-4">
                        <v-text-field
                          label="Region"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.adress_region.name"
                          disabled
                        ></v-text-field>
                      </div>

                      <div class="col-4">
                        <v-text-field
                          label="Status"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.status.status"
                          disabled
                        ></v-text-field>
                      </div>
                      <div class="col-4">
                        <v-text-field
                          label="Mulchilik shakli"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.ownership.name"
                          disabled
                        ></v-text-field>
                      </div>
                      <div class="col-4">
                        <v-text-field
                          label="Pochta manzili"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.pochta_address"
                          disabled
                        ></v-text-field>
                      </div>

                      <div class="col-4">
                        <v-text-field
                          label="Yuridik manzil"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.yur_address"
                          disabled
                        ></v-text-field>
                      </div>
                      <div class="col-4">
                        <v-text-field
                          label="Izoh"
                          outlined
                          dense
                          v-model="getCurrProviderDetails.comments"
                          disabled
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <!-- 2 -->
              <v-tab-item>
                <div v-if="getCurrProviderDetails.juridical_type == 'JI'">
                  <h5 style="font-weight: 300" class="m-0 text-danger">
                    Jismoniy shaxs uchun kontakt malumotlari kiritilmagan
                  </h5>
                </div>
                <div v-else>
                  <!-- director -->
                  <div class="my-5">
                    <div class="mb-5">
                      <h5 style="font-weight: 300" class="m-0">
                        Direktor malumotlari
                      </h5>
                    </div>
                    <div v-if="getCurrProviderDetails.juridical_type == 'YU'">
                      <div>
                        <v-text-field
                          outlined
                          dense
                          disabled
                          :value="getCurrProviderDetails.director_name"
                          label="Direktor nomi"
                        ></v-text-field>
                      </div>
                      <div class="d-flex">
                        <div class="col-6 pl-0">
                          <v-text-field
                            outlined
                            dense
                            disabled
                            :value="getCurrProviderDetails.director_phone1"
                            label="Telefon raqam"
                          ></v-text-field>
                        </div>
                        <div class="col-6 pr-0">
                          <v-text-field
                            outlined
                            dense
                            disabled
                            :value="getCurrProviderDetails.director_phone2"
                            label="Telefon raqam"
                          ></v-text-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- staff -->
                  <div class="mb-5">
                    <div class="mb-5">
                      <h5 style="font-weight: 300" class="m-0">
                        Hodim malumotlari
                      </h5>
                    </div>
                    <div v-if="getCurrProviderDetails.juridical_type == 'YU'">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 pl-0">
                          <v-text-field
                            disabled
                            label="Hodim FIO"
                            outlined
                            dense
                            :value="
                              getCurrProviderDetails.contact_personal_name
                            "
                          ></v-text-field>
                        </div>

                        <div class="col-6">
                          <v-text-field
                            disabled
                            label="Lavozim"
                            outlined
                            dense
                            :value="
                              getCurrProviderDetails.lavozim_name !== null
                                ? getCurrProviderDetails.lavozim_name.name
                                : 'Kiritilinmagan'
                            "
                          ></v-text-field>
                        </div>
                        <div class="col-6 pl-0">
                          <v-text-field
                            disabled
                            label="Telefon raqam"
                            outlined
                            dense
                            :value="
                              getCurrProviderDetails.contact_personal_phone1
                            "
                          ></v-text-field>
                        </div>
                        <div class="col-6 pr-0">
                          <v-text-field
                            disabled
                            label="Telefon raqam"
                            outlined
                            dense
                            :value="
                              getCurrProviderDetails.contact_personal_phone2
                            "
                          ></v-text-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- bank info -->
                  <div class="mb-5">
                    <div class="mb-5">
                      <h6 style="font-weight: 300" class="m-0">
                        Bank malumotlari
                      </h6>
                    </div>

                    <div
                      class="d-flex"
                      v-if="getCurrProviderDetails.juridical_type == 'YU'"
                    >
                      <div class="col-6 pl-0">
                        <v-text-field
                          disabled
                          label="Bank mfo raqami"
                          outlined
                          dense
                          :value="getCurrProviderDetails.bank_code1"
                        ></v-text-field>
                      </div>
                      <div class="col-6 pr-0">
                        <v-text-field
                          disabled
                          label="Bank hisob raqami"
                          outlined
                          dense
                          :value="getCurrProviderDetails.bank_account1"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <!-- 3 -->
              <v-tab-item>
                <div>
                  <h5 class="mb-10">Shartnomalar</h5>
                  <div>
                    <v-data-table
                      :headers="providerContractHeaders"
                      :items="getProviderContract"
                      :items-per-page="5"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.contract_type`]="{ item }">
                        <div
                          class="status__name mb-0"
                          :class="item.contract_class_name"
                        >
                          {{ item.contract_type }}
                        </div>
                      </template>
                      <template v-slot:[`item.is_active`]="{ item }">
                        <div class="status__name" :class="item.is_active_color">
                          {{ item.is_active }}
                        </div>
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <div style="text-align: right !important">
                          <action :index="item"></action>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item>
                <div>
                  <div class="row justify-content-end">
                    <div class="col">
                      <v-btn
                        @click="dialog = true"
                        :loading="isLoading"
                        small
                        color="primary"
                      >
                        +
                      </v-btn>
                    </div>
                  </div>
                  <div class="mt-10">
                    <v-data-table
                      :headers="startingHeaders"
                      :items="getProviderStartingPoint"
                      :items-per-page="5"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.oper_date`]="{ item }">
                        {{ item.oper_date | dateFilter }}
                      </template>
                      <template v-slot:[`item.debet_amount`]="{ item }">
                        {{ item.debet_amount }}
                      </template>
                      <template v-slot:[`item.credit_amount`]="{ item }">
                        {{ item.credit_amount }}
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <button
                          type="button"
                          @click="edit(item)"
                          class="
                            btn btn-icon btn-light btn-hover-primary btn-sm
                            mx-3
                          "
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Write.svg-->
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                                ></path>
                                <path
                                  d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  opacity="0.3"
                                ></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                        </button>
                        <button
                          @click="deletePoint(item.id)"
                          type="button"
                          class="
                            btn btn-icon btn-light btn-hover-primary btn-sm
                          "
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </button>
                      </template>
                    </v-data-table>
                  </div>
                  <v-dialog v-model="dialog" persistent max-width="600px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">
                        Boshlang'ich qoldiq kiritish
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  dense
                                  outlined
                                  v-model="date"
                                  label="Sana"
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="date"
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              label="Debet"
                              v-model="debet"
                              v-currency="options"
                              outlined
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-currency="options"
                              outlined
                              dense
                              v-model="credit"
                              label="Credit"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="
                          dialog = false
                          creating = true
                        "
                      >
                        Bekor qilish
                      </v-btn>
                      <v-btn
                        :loading="isLoading"
                        color="blue darken-1"
                        text
                        @click="send"
                      >
                        Saqlash
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import action from './actionDetails'
import Swal from 'sweetalert2'
export default {
  data() {
    return {
      editingId: '',
      creating: true,
      dialog: false,
      date: '',
      debet: '',
      credit: '',
      tab: null,
      hideCurrencySymbol: true,
      distractionFree: false,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      menu2: false,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      providerContractHeaders: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Postavshik nomi',
          value: 'provider_name'
        },
        {
          text: 'Inn',
          value: 'provider_inn'
        },
        {
          text: 'Raqam',
          value: 'contract_number'
        },
        {
          text: 'Summa',
          value: 'amount'
        },
        {
          text: 'Boshlanish',
          value: 'start_date'
        },
        {
          text: 'Tugash',
          value: 'end_date'
        },
        {
          text: 'Turi',
          value: 'contract_type'
        },
        {
          text: 'Status',
          value: 'is_active'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],
      items: [
        {
          tab: 'Umumiy malumotlar'
        },
        {
          tab: 'Kontakt malumotlari'
        },
        { tab: 'Shartnomalar' },
        { tab: 'Boshlang’ich qoldiq' }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('getProviderDetails', this.$route.params.id)
    this.$store.dispatch('getProviderStartingPoint', this.$route.params.id)
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getProviderContract() {
      return this.$store.getters.allProviderContractById
    },
    getCurrProviderDetails() {
      return this.$store.state.requests.providerDetails
    },
    startingHeaders() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Sana',
          value: 'oper_date'
        },
        {
          text: 'Debet',
          value: 'debet_amount'
        },
        {
          text: 'Kredit',
          value: 'credit_amount'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    },
    getProviderStartingPoint() {
      return this.$store.state.requests.allProviderStartingPoint.results
    },
    options() {
      return {
        locale: 'zh-ZH',
        currency: [null],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Postavshiklar' },
      { title: 'Postavshik malumotlari' }
    ])
  },
  methods: {
    tabNum(value) {
      if (value == '2') {
        this.$store.dispatch('getProviderContractById', this.$route.params.id)
      }
    },
    edit(payload) {
      this.editingId = payload.id
      this.creating = false
      this.dialog = true
      this.date = payload.oper_date
      this.debet = payload.debet_amount.toLocaleString('es-US')
      this.credit = payload.credit_amount.toLocaleString('es-US')
    },
    deletePoint(payload) {
      Swal.fire({
        title: 'Tasdiqlang',
        text: "Ma'lumot ochrilganidan so'ng qayta tiklay olmaysiz!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Xa, o'chirish",
        cancelButtonText: 'Bekor qilish'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire('Ochirildi!', '', 'success')
          this.$store.dispatch('deleteProviderStartingPoint', {
            id: payload,
            client: this.$route.params.id
          })
        }
      })
    },
    send() {
      if (this.date !== '' && this.debet !== '' && this.credit !== '') {
        const data = {
          client: this.$route.params.id,
          oper_date: this.date,
          debet_amount: parseInt(this.debet.replace(/[ ,]/g, ''), 10),
          credit_amount: parseInt(this.credit.replace(/[ ,]/g, ''), 10)
        }
        if (this.creating) {
          this.$store.dispatch('createProviderStartingPoint', data).then(() => {
            this.dialog = false
            this.date = ''
            this.debet = ''
            this.credit = ''
          })
        } else {
          this.$store
            .dispatch('updateProviderStartingPoint', { data: data, id: this.editingId })
            .then(() => {
              this.dialog = false
              this.date = ''
              this.debet = ''
              this.credit = ''
            })
        }
      } else {
        Swal.fire({
          title: '',
          text: 'Formada xatolik bor',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      }
    },
  },
  components: {
    action
  }
}
</script>

<style scoped>
.alert.alert-success {
  margin-bottom: 0 !important;
}
.col-4,
.col-12 {
  padding-left: 0;
}
.input__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
  margin-bottom: 0 !important;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
